package dev.moetz.chatoverlay.model

import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

@JvmInline
@Serializable
value class TwitchChannelName(val channelName: String) {
    val lowerCased: String get() = channelName.lowercase()
    val isPreview: Boolean get() = channelName.equals("previewuser", ignoreCase = true)
}

package dev.moetz.chatoverlay.model

sealed class IncomingIRC {

    companion object {

        private fun parseTags(rawTags: String): Map<String, String> {
            return rawTags.split(";")
                .asSequence()
                .mapNotNull { keyAndValue ->
                    val split = keyAndValue.split("=", limit = 2)
                    if (split.size == 2 && split[1].isNotBlank()) {
                        split[0] to split[1]
                    } else {
                        null
                    }
                }
                .toMap()
        }

        private fun String.toBadgesSetNameWithNumberList(): List<BadgeSetNameWithNumber> {
            return this.split(",")
                .mapNotNull { rawBadge ->
                    //subscriber/24,rplace-2023/1
                    val setNameAndPosition = rawBadge.split("/", limit = 2)
                    val setName = setNameAndPosition.getOrNull(0)?.takeIf { it.isNotBlank() }
                    val value = setNameAndPosition.getOrNull(1)?.takeIf { it.isNotBlank() }
                    if (setName != null && value != null) {
                        BadgeSetNameWithNumber(
                            set = setName,
                            value = value
                        )
                    } else {
                        null
                    }
                }
        }

        fun parse(rawMessage: String): IncomingIRC? {
            return try {
                val messageParts = rawMessage.split(" ", limit = 5)

                when {
                    messageParts.getOrNull(2) == "PRIVMSG" -> {
                        // we are a message
                        val tags = parseTags(messageParts.getOrNull(0).orEmpty().drop(1))
                        val channel = messageParts.getOrNull(3)?.drop(1).orEmpty()
                        val (message, isSlashMeMessage) = messageParts.getOrNull(4)
                            ?.let { raw ->
                                if (raw.firstOrNull() == ':') {
                                    raw.drop(1)
                                } else {
                                    raw
                                }
                            }
                            .orEmpty()
                            .let { msg ->
                                if (msg.firstOrNull()?.code == 1) {
                                    // we are a '/me' message
                                    Pair(
                                        msg
                                            .dropWhile { it.code == 1 }
                                            .substring("ACTION ".length)
                                            .takeWhile { it.code != 1 }, true
                                    )
                                } else {
                                    // we are a normal message
                                    Pair(msg, false)
                                }
                            }

                        Message(
                            badgeInfo = tags["badge-info"],
                            badges = tags["badges"]?.toBadgesSetNameWithNumberList().orEmpty(),
                            color = tags["color"],
                            displayName = tags["display-name"]?.replace("\\s", ""),
                            emotes = tags["emotes"].orEmpty().split("/")
                                .flatMap { raw ->
                                    val split = raw.split(":", limit = 2)
                                    if (split.size == 2) {
                                        val locations = split[1].split(",")
                                        locations.mapNotNull { location ->
                                            val indexe = location.trim().split("-", limit = 2)
                                            val startIndex = indexe.getOrNull(0)?.toIntOrNull()
                                            val endIndex = indexe.getOrNull(1)?.toIntOrNull()
                                            if (startIndex != null && endIndex != null) {
                                                EmoteWithLocation(
                                                    emoteId = split[0],
                                                    startIndex = startIndex,
                                                    endIndex = endIndex,
                                                    name = message.substring(startIndex, endIndex + 1),
                                                )
                                            } else {
                                                null
                                            }
                                        }
                                    } else {
                                        emptyList()
                                    }
                                },
                            bits = tags["bits"]?.toIntOrNull(),
                            isFirstMessage = tags["first-msg"] == "1",
                            flags = tags["flags"],
                            id = tags["id"]!!,
                            isModerator = tags["mod"] == "1",
                            returningChatter = tags["returning-chatter"] == "1",
                            roomId = tags["room-id"],
                            subscriber = tags["subscriber"] == "1",
                            tmiSentTimestamp = tags["tmi-sent-ts"],
                            isTurbo = tags["turbo"] == "1",
                            userId = tags["user-id"],
                            userType = tags["user-type"],
                            channel = channel,
                            message = message,
                            isSlashMe = isSlashMeMessage,
                            // source-badge-info
                            sourceBadgeInfo = tags["source-badge-info"],
                            // source-badges
                            sourceBadges = tags["source-badges"]?.toBadgesSetNameWithNumberList(),
                            // source-id
                            sourceId = tags["source-id"],
                            // source-room-id
                            sourceRoomId = tags["source-room-id"],
                        )
                    }

                    messageParts.getOrNull(2) == "USERNOTICE" -> {
                        // we are a sub / resub / subgift
                        val tags = parseTags(messageParts.getOrNull(0).orEmpty().drop(1))
                        val channel = messageParts.getOrNull(3)?.drop(1).orEmpty()
                        val message = messageParts.getOrNull(4)
                            ?.let { raw ->
                                if (raw.firstOrNull() == ':') {
                                    raw.drop(1)
                                } else {
                                    raw
                                }
                            }
                            .orEmpty()

                        Usernotice(
                            badgeInfo = tags["badge-info"],
                            badges = tags["badges"]?.split(",")
                                .orEmpty()
                                .mapNotNull { rawBadge ->
                                    //subscriber/24,rplace-2023/1
                                    val setNameAndPosition = rawBadge.split("/", limit = 2)
                                    val setName = setNameAndPosition.getOrNull(0)?.takeIf { it.isNotBlank() }
                                    val value = setNameAndPosition.getOrNull(1)?.takeIf { it.isNotBlank() }
                                    if (setName != null && value != null) {
                                        BadgeSetNameWithNumber(
                                            set = setName,
                                            value = value
                                        )
                                    } else {
                                        null
                                    }
                                },
                            color = tags["color"],
                            displayName = tags["display-name"],
                            emotes = tags["emotes"].orEmpty().split("/")
                                .flatMap { raw ->
                                    val split = raw.split(":", limit = 2)
                                    if (split.size == 2) {
                                        val locations = split[1].split(",")
                                        locations.mapNotNull { location ->
                                            val indexe = location.trim().split("-", limit = 2)
                                            val startIndex = indexe.getOrNull(0)?.toIntOrNull()
                                            val endIndex = indexe.getOrNull(1)?.toIntOrNull()
                                            if (startIndex != null && endIndex != null) {
                                                EmoteWithLocation(
                                                    emoteId = split[0],
                                                    startIndex = startIndex,
                                                    endIndex = endIndex,
                                                    name = message.substring(startIndex, endIndex + 1),
                                                )
                                            } else {
                                                null
                                            }
                                        }
                                    } else {
                                        emptyList()
                                    }
                                },
                            bits = tags["bits"]?.toIntOrNull(),
                            isFirstMessage = tags["first-msg"] == "1",
                            flags = tags["flags"],
                            id = tags["id"]!!,
                            isModerator = tags["mod"] == "1",
                            returningChatter = tags["returning-chatter"] == "1",
                            roomId = tags["room-id"],
                            subscriber = tags["subscriber"] == "1",
                            tmiSentTimestamp = tags["tmi-sent-ts"],
                            isTurbo = tags["turbo"] == "1",
                            userId = tags["user-id"],
                            userType = tags["user-type"],
                            channel = channel,
                            message = message,
                            systemMessage = tags["system-msg"]?.replace("\\s", " "),
                            messageId = tags["msg-id"],
                            cumulativeMonths = tags["msg-param-cumulative-months"]?.toIntOrNull(),
                            months = tags["msg-param-months"]?.toIntOrNull(),
                            multiMonthDuration = tags["msg-param-multimonth-duration"]?.toIntOrNull(),
                            multiMonthTenure = tags["msg-param-multimonth-tenure"]?.toIntOrNull(),
                            shouldShareStreak = tags["msg-param-should-share-streak"] == "1",
                            subPlanName = tags["msg-param-sub-plan-name"]?.replace("\\s", " "),
                            subPlan = tags["msg-param-sub-plan"],
                            wasGifted = tags["msg-param-was-gifted"] == "true",
                        )
                    }

                    messageParts.getOrNull(2) == "CLEARCHAT" -> {
                        val tags = parseTags(messageParts.getOrNull(0).orEmpty().drop(1))
                        val channel = messageParts.getOrNull(3)?.drop(1).orEmpty()
                        val message = messageParts.getOrNull(4)?.drop(1).orEmpty()

                        val targetUserId = tags["target-user-id"]

                        if (targetUserId != null) {
                            ClearChat(
                                banDuration = tags["ban-duration"]?.toIntOrNull(),
                                roomId = tags["room-id"],
                                targetUserId = targetUserId,
                                tmiSentTimestamp = tags["tmi-sent-ts"],
                                channelName = channel,
                                displayName = message,
                            )
                        } else {
                            null
                        }
                    }

                    messageParts.getOrNull(2) == "CLEARMSG" -> {
                        val tags = parseTags(messageParts.getOrNull(0).orEmpty().drop(1))
                        val channel = messageParts.getOrNull(3)?.drop(1).orEmpty()
                        val message = messageParts.getOrNull(4)?.drop(1).orEmpty()

                        val targetMessageId = tags["target-msg-id"]

                        if (targetMessageId != null) {
                            ClearMessage(
                                login = tags["login"],
                                roomId = tags["room-id"],
                                targetMessageId = targetMessageId,
                                tmiSentTimestamp = tags["tmi-sent-ts"],
                                channelName = channel,
                                message = message,
                            )
                        } else {
                            null
                        }
                    }

                    else -> null
                }
            } catch (throwable: Throwable) {
                throwable.printStackTrace()
                null
            }
        }
    }

    data class Message(
        val badgeInfo: String?,
        val badges: List<BadgeSetNameWithNumber>,
        val bits: Int?,
        val color: String?,
        val displayName: String?,
        val emotes: List<EmoteWithLocation>,
        val isFirstMessage: Boolean,
        val flags: String?,
        val id: String,
        val isModerator: Boolean,
        val returningChatter: Boolean,
        val roomId: String?,
        val subscriber: Boolean,
        val tmiSentTimestamp: String?,
        val isTurbo: Boolean,
        val userId: String?,
        val userType: String?,
        val channel: String?,
        val message: String,
        val isSlashMe: Boolean,
        // source-badge-info
        val sourceBadgeInfo: String?,
        // source-badges
        val sourceBadges: List<BadgeSetNameWithNumber>?,
        // source-id
        val sourceId: String?,
        // source-room-id
        val sourceRoomId: String?,
    ) : IncomingIRC() {

        val actualRoomId: String? get() = sourceRoomId ?: roomId

        val isFromDifferentRoom: Boolean get() = sourceRoomId != null && roomId != sourceRoomId

        val actualBadges: List<BadgeSetNameWithNumber> get() = sourceBadges ?: badges

    }

    data class Usernotice(
        val badgeInfo: String?,
        val badges: List<BadgeSetNameWithNumber>,
        val bits: Int?,
        val color: String?,
        val displayName: String?,
        val emotes: List<EmoteWithLocation>,
        val isFirstMessage: Boolean,
        val flags: String?,
        val id: String,
        val isModerator: Boolean,
        val returningChatter: Boolean,
        val roomId: String?,
        val subscriber: Boolean,
        val tmiSentTimestamp: String?,
        val isTurbo: Boolean,
        val userId: String?,
        val userType: String?,
        val channel: String?,
        val message: String,
        val systemMessage: String?,
        val messageId: String?,
        val cumulativeMonths: Int?,
        val months: Int?,
        val multiMonthDuration: Int?,
        val multiMonthTenure: Int?,
        val shouldShareStreak: Boolean?,
        val subPlanName: String?,
        val subPlan: String?,
        val wasGifted: Boolean?,
    ) : IncomingIRC() {

        fun toMessage(): Message? {
            return if (message.isBlank()) {
                null
            } else {
                Message(
                    badgeInfo = badgeInfo,
                    badges = badges,
                    bits = bits,
                    color = color,
                    displayName = displayName,
                    emotes = emotes,
                    isFirstMessage = isFirstMessage,
                    flags = flags,
                    id = id,
                    isModerator = isModerator,
                    returningChatter = returningChatter,
                    roomId = roomId,
                    subscriber = subscriber,
                    tmiSentTimestamp = tmiSentTimestamp,
                    isTurbo = isTurbo,
                    userId = userId,
                    userType = userType,
                    channel = channel,
                    message = message,
                    isSlashMe = false,
                    sourceBadgeInfo = null,
                    sourceBadges = null,
                    sourceId = null,
                    sourceRoomId = null,
                )
            }
        }
    }


    data class EmoteWithLocation(
        val emoteId: String,
        val startIndex: Int,
        val endIndex: Int,
        val name: String,
    )

    data class BadgeSetNameWithNumber(
        val set: String,
        val value: String,
    )

    data class ClearChat(
        val banDuration: Int?,
        val roomId: String?,
        val targetUserId: String,
        val tmiSentTimestamp: String?,
        val channelName: String?,
        val displayName: String?,
    ) : IncomingIRC()


    data class ClearMessage(
        val login: String?,
        val roomId: String?,
        val targetMessageId: String,
        val tmiSentTimestamp: String?,
        val channelName: String?,
        val message: String?,
    ) : IncomingIRC()

}
package dev.moetz.chatoverlay.util

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import react.useEffect
import react.useState

fun <T> StateFlow<T>.asState(): T {
    val (state, setState) = useState(this.value)

    //https://stackoverflow.com/questions/62690722/how-to-subscribe-to-stateflow-in-kotlin-react-useeffect
    useEffect() {
        this@asState.onEach { setState(it) }.launchIn(this)
    }

    return state
}

fun <T> Flow<T>.asState(initial: T): T {
    val (state, setState) = useState(initial)

    //https://stackoverflow.com/questions/62690722/how-to-subscribe-to-stateflow-in-kotlin-react-useeffect
    useEffect() {
        this@asState.onEach { setState(it) }.launchIn(this)
    }

    return state
}
package dev.moetz.chatoverlay.chatclient

import dev.moetz.chatoverlay.model.BroadcasterId
import dev.moetz.chatoverlay.model.IncomingIRC
import dev.moetz.chatoverlay.model.thirdpartyemote.ThirdPartyEmote
import dev.moetz.chatoverlay.model.twitch.BadgeSet
import dev.moetz.chatoverlay.model.twitch.Cheermote
import dev.moetz.chatoverlay.model.twitch.User
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.datetime.Clock
import kotlinx.serialization.json.Json
import kotlin.random.Random

class PreviewChatClient(
    apiBaseUrl: String,
    loggingEnabled: Boolean,
    json: Json = Json,
    private val messageAllowedPredicate: (IncomingIRC.Message) -> Boolean = { true },
    private val scope: CoroutineScope = GlobalScope,
) : ChatClient(
    apiBaseUrl = apiBaseUrl,
    loggingEnabled = loggingEnabled,
    json = json,
) {

    private val mutableMessageStateFlow = MutableStateFlow(
        listOf(
            IncomingIRC.Message(
                badgeInfo = null,
                badges = emptyList(),
                bits = null,
                color = null,
                displayName = "Chatter1",
                emotes = emptyList(),
                isFirstMessage = false,
                flags = null,
                id = "1234123412341234",
                isModerator = false,
                returningChatter = false,
                roomId = "12341234",
                subscriber = false,
                tmiSentTimestamp = "1234123412341234",
                isTurbo = false,
                userId = "1",
                userType = "user",
                channel = "PreviewUser",
                message = "Hello there!",
                isSlashMe = false,
                sourceBadgeInfo = null,
                sourceBadges = null,
                sourceId = null,
                sourceRoomId = null,
            ),
            IncomingIRC.Message(
                badgeInfo = "subscriber/17",
                badges = listOf(
                    IncomingIRC.BadgeSetNameWithNumber(set = "subscriber", value = "12"),
                    IncomingIRC.BadgeSetNameWithNumber(set = "rplace-2023", value = "1")
                ),
                bits = null,
                color = "#FF69B4",
                displayName = "ThisIsMe",
                emotes = emptyList(),
                isFirstMessage = false,
                flags = null,
                id = "003e5765-e945-4322-847f-601d3a0fee24",
                isModerator = false,
                returningChatter = false,
                roomId = "1",
                subscriber = true,
                tmiSentTimestamp = "1703286947800",
                isTurbo = false,
                userId = "1234",
                userType = null,
                channel = "PreviewUser",
                message = "Is this working? monkaS",
                isSlashMe = false,
                sourceBadgeInfo = null,
                sourceBadges = null,
                sourceId = null,
                sourceRoomId = null,
            ),
            IncomingIRC.Message(
                badgeInfo = "subscriber/32",
                badges = listOf(
                    IncomingIRC.BadgeSetNameWithNumber(set = "subscriber", value = "12"),
                    IncomingIRC.BadgeSetNameWithNumber(set = "twitch-recap-2023", value = "1")
                ),
                bits = null,
                color = "#1E90FF",
                displayName = "TestAccount",
                emotes = emptyList(),
                isFirstMessage = false,
                flags = null,
                id = "003e5765-e945-4322-847f-601d3a0fee21",
                isModerator = false,
                returningChatter = false,
                roomId = "1",
                subscriber = true,
                tmiSentTimestamp = "1703286947801",
                isTurbo = false,
                userId = "1235",
                userType = null,
                channel = "PreviewUser",
                message = "!uptime",
                isSlashMe = false,
                sourceBadgeInfo = null,
                sourceBadges = null,
                sourceId = null,
                sourceRoomId = null,
            ),
            IncomingIRC.Message(
                badgeInfo = "subscriber/40",
                badges = listOf(
                    IncomingIRC.BadgeSetNameWithNumber(set = "moderator", value = "1"),
                    IncomingIRC.BadgeSetNameWithNumber(set = "subscriber", value = "36"),
                    IncomingIRC.BadgeSetNameWithNumber(set = "partner", value = "1")
                ),
                bits = null,
                color = "#7C7CE1",
                displayName = "Nightbot",
                emotes = emptyList(),
                isFirstMessage = false,
                flags = null,
                id = "003e5765-e945-4322-847f-601d3a0fee21",
                isModerator = true,
                returningChatter = false,
                roomId = "1",
                subscriber = true,
                tmiSentTimestamp = "1703286947801",
                isTurbo = false,
                userId = "1235",
                userType = "mod",
                channel = "PreviewUser",
                message = "Channel is not live",
                isSlashMe = false,
                sourceBadgeInfo = null,
                sourceBadges = null,
                sourceId = null,
                sourceRoomId = null,
            )
        )
            .filter { messageAllowedPredicate.invoke(it) }
    )

    private val numberOfMessagesInLoop = 4

    private val random = Random(Clock.System.now().epochSeconds)

    override val connectedFlow: StateFlow<Boolean> = MutableStateFlow(true)

    override val messagesStateFlow: StateFlow<List<IncomingIRC.Message>> =
        mutableMessageStateFlow
            .asStateFlow()

    override val seenRoomIdsFlow: Flow<Set<BroadcasterId>> = MutableStateFlow(setOf(BroadcasterId("0")))

    override suspend fun connectAndJoinChannels(channels: List<String>, loadRecentMessages: Boolean) {
        //Nothing to do here for now?

        scope.launch {
            var loopVar = 0
            while (this.isActive) {
                delay(500 + (random.nextLong(5) * 250))

                val newMessage = mutableMessageStateFlow.value[loopVar % numberOfMessagesInLoop]

                loopVar++

                mutableMessageStateFlow.value = mutableMessageStateFlow.value.toMutableList()
                    .apply { add(newMessage) }

                if (loopVar >= numberOfMessagesInLoop) {
                    loopVar = 0
                }
            }
        }
    }

    override suspend fun getChannelEmotes(channelName: String): BadgeSet? = null

    override suspend fun getChannelEmotesByBroadcasterId(broadcasterId: BroadcasterId): List<BadgeSet>? = null

    override suspend fun getCheermotes(broadcasterId: BroadcasterId): List<Cheermote>? = null

    override suspend fun getUser(channelName: String): User {
        return User(
            id = "1",
            login = "PreviewUser",
            displayName = "PreviewUser",
            type = "user",
            broadcasterType = "partner",
            description = "No description",
            profileImageUrl = "https://static.twitchcdn.net/assets/favicon-32-e29e246c157142c94346.png",
            offlineImageUrl = "",
            viewCount = 1,
            createdAt = ""
        )
    }

    override suspend fun getCombinedChannelEmotes(broadcasterId: BroadcasterId): List<ThirdPartyEmote>? {
        return emptyList()
    }

}

package dev.moetz.chatoverlay.page

import dev.moetz.chatoverlay.design.mFooter
import dev.moetz.materialize.mCol
import dev.moetz.materialize.mContainer
import dev.moetz.materialize.mHeader
import dev.moetz.materialize.mRow
import dev.moetz.werbinich.localization.Localization
import react.FC
import react.Props
import react.dom.html.ReactHTML.p

external interface NotFoundPageServiceProps : Props {
}

val NotFoundPageService = FC<NotFoundPageServiceProps> { props ->
    mHeader(title = Localization["page_title"])

    mContainer {
        mRow {
            mCol(small = 12) {
                p {
                    +Localization["page_not_found"]
                }
            }
        }
    }

    mFooter()
}
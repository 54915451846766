package dev.moetz.chatoverlay.chatclient

import dev.moetz.chatoverlay.RecentMessagesLoader
import dev.moetz.chatoverlay.irc.IRC
import dev.moetz.chatoverlay.model.BroadcasterId
import dev.moetz.chatoverlay.model.IncomingIRC
import dev.moetz.chatoverlay.model.thirdpartyemote.ThirdPartyEmote
import dev.moetz.chatoverlay.model.twitch.BadgeSet
import dev.moetz.chatoverlay.model.twitch.Cheermote
import dev.moetz.chatoverlay.model.twitch.User
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

class TwitchChatClient(
    apiBaseUrl: String,
    private val messageShowCount: Int,
    loggingEnabled: Boolean,
    json: Json = Json,
    private val messageAllowedPredicate: (IncomingIRC.Message) -> Boolean = { true },
    private val scope: CoroutineScope = GlobalScope,
    private val pingInterval: Duration = 60.seconds,
    private val pongReceiveTimeout: Duration = 10.seconds,
    private val messageDelay: Duration? = null,
) : ChatClient(
    apiBaseUrl = apiBaseUrl,
    loggingEnabled = loggingEnabled,
    json = json,
) {

    private val irc: IRC = IRC(
        host = "irc-ws.chat.twitch.tv",
        port = 443,
        path = "/",
        loggingEnabled = loggingEnabled,
    )

    override val connectedFlow: StateFlow<Boolean> get() = irc.connectedStateFlow

    private val mutableMessagesStateFlow: MutableStateFlow<List<IncomingIRC.Message>> =
        MutableStateFlow(emptyList())
    private val messagesStateFlowMutex = Mutex()

    private val publicMutableMessagesStateFlow: MutableStateFlow<List<IncomingIRC.Message>> =
        MutableStateFlow(emptyList())
    override val messagesStateFlow: StateFlow<List<IncomingIRC.Message>> = publicMutableMessagesStateFlow.asStateFlow()

    private val recentMessagesLoader = RecentMessagesLoader(
        loggingEnabled = loggingEnabled,
        json = json,
    )

    override val seenRoomIdsFlow: Flow<Set<BroadcasterId>> = messagesStateFlow
        .map { messages ->
            messages.asSequence()
                .mapNotNull { message -> message.actualRoomId?.let { BroadcasterId(it) } }
                .toSet()
                .sortedBy { it.id }
                .toSet()
        }
        .distinctUntilChanged()
        .flowOn(Dispatchers.Default)

    init {
        if (messageDelay != null) {
            mutableMessagesStateFlow
                .map { list ->
                    scope.launch {
                        delay(messageDelay)
                        publicMutableMessagesStateFlow.value = list
                            .filter { message -> mutableMessagesStateFlow.value.any { it == message } }
                            .map { message -> message }
                    }
                }
                .launchIn(scope)
        } else {
            mutableMessagesStateFlow
                .map { list -> publicMutableMessagesStateFlow.value = list }
                .launchIn(scope)
        }
    }

    private suspend fun addMessage(incomingMessage: IncomingIRC.Message) {
        messagesStateFlowMutex.withLock {
            mutableMessagesStateFlow.value = mutableMessagesStateFlow.value
                .toMutableList()
                .apply {
                    add(incomingMessage)
                    try {
                        while (this.size > messageShowCount) {
                            this.removeAt(0)
                        }
                    } catch (throwable: Throwable) {
                        throwable.printStackTrace()
                    }
                }
        }
    }

    private suspend fun clearMessages() {
        messagesStateFlowMutex.withLock {
            mutableMessagesStateFlow.value = emptyList()
        }
    }

    private var connectionJob: Job? = null

    override suspend fun connectAndJoinChannels(channels: List<String>, loadRecentMessages: Boolean) {
        // clear up if possibly reconnecting
        clearMessages()
        irc.disconnect()
        connectionJob?.cancel()

        irc.connect()
        println("IRC connected")
        irc.send("CAP REQ :twitch.tv/tags twitch.tv/commands twitch.tv/membership")
        irc.send("PASS SCHMOOPIIE")
        irc.send("NICK justinfan41313")
        irc.join(channels)
        println("joined channels")

        connectionJob = scope.launch {
            if (loadRecentMessages) {
                println("Loading recent messages")
                channels
                    .map { channel ->
                        async {
                            recentMessagesLoader.getRecentMessages(channelName = channel, limit = messageShowCount)
                                .also {
                                    if (it == null) {
                                        println("Error loading recent messages for $channel")
                                    }
                                }
                                .orEmpty()
                        }
                    }
                    .flatMap { channelDeferred -> channelDeferred.await() }
                    .sortedBy { it.tmiSentTimestamp }
                    .filter { messageAllowedPredicate.invoke(it) }
                    .takeLast(messageShowCount)
                    .forEach { recentMessage -> addMessage(recentMessage) }
                println("Recent messages loaded")
            }

            irc.incomingMessagesFlow
                .flatMapConcat {
                    if (it.contains("\n")) {
                        it.split("\n").asFlow()
                    } else {
                        flowOf(it)
                    }
                }
                .mapNotNull { IncomingIRC.parse(it) }
                .onEach { incoming ->
                    when (incoming) {
                        is IncomingIRC.Message -> {
                            if (messageAllowedPredicate.invoke(incoming)) {
                                addMessage(incoming)
                            }
                        }

                        is IncomingIRC.Usernotice -> {
                            val message = incoming.toMessage()
                            if (message != null && messageAllowedPredicate.invoke(message)) {
                                addMessage(message)
                            }
                        }

                        is IncomingIRC.ClearChat -> {
                            messagesStateFlowMutex.withLock {
                                mutableMessagesStateFlow.value = mutableMessagesStateFlow.value
                                    .toMutableList()
                                    .apply {
                                        try {
                                            removeAll { message -> message.userId == incoming.targetUserId && message.channel == incoming.channelName }
                                        } catch (throwable: Throwable) {
                                            throwable.printStackTrace()
                                        }
                                    }
                            }
                        }

                        is IncomingIRC.ClearMessage -> {
                            messagesStateFlowMutex.withLock {
                                mutableMessagesStateFlow.value = mutableMessagesStateFlow.value
                                    .toMutableList()
                                    .apply {
                                        try {
                                            removeAll { message -> message.id == incoming.targetMessageId && message.channel == incoming.channelName }
                                        } catch (throwable: Throwable) {
                                            throwable.printStackTrace()
                                        }
                                    }
                            }
                        }
                    }
                }
                .launchIn(this)

            // ping observer
            irc.incomingMessagesFlow
                .filter { it.startsWith("PING") }
                .onEach {
                    val pingMessage = it.substringAfter("PING ")
                    irc.send("PONG $pingMessage")
                }
                .launchIn(this)

            // ping sender
            this.launch {
                while (this.isActive) {
                    delay(pingInterval)

                    val pongReceivedDeferred = async {
                        try {
                            withTimeout(pongReceiveTimeout) {
                                irc.incomingMessagesFlow
                                    .filter { it.startsWith("PONG") }
                                    .first()
                            }
                            true
                        } catch (throwable: Throwable) {
                            println("PONG not received in time")
                            false
                        }
                    }
                    irc.send("PING")

                    val pongReceived = pongReceivedDeferred.await()
                    if (pongReceived.not()) {
                        scope.launch {
                            println("cancelling current connection and connecting again")
                            connectAndJoinChannels(channels = channels, loadRecentMessages = loadRecentMessages)
                        }
                    }
                }
            }
        }
    }

    override suspend fun getChannelEmotes(channelName: String): BadgeSet? {
        val response =
            httpClient.get(urlString = "${apiBaseUrl.substringBeforeLast("/")}/api/twitch/badges/channel/$channelName")
        return if (response.status.isSuccess()) {
            val text = response.bodyAsText()
            json.decodeFromString(ListSerializer(BadgeSet.serializer()), text).firstOrNull()
        } else {
            println("Api error: $response")
            null
        }
    }

    override suspend fun getChannelEmotesByBroadcasterId(broadcasterId: BroadcasterId): List<BadgeSet>? {
        val response =
            httpClient.get(urlString = "${apiBaseUrl.substringBeforeLast("/")}/api/twitch/badges/broadcasterId/${broadcasterId.id}")
        return if (response.status.isSuccess()) {
            val text = response.bodyAsText()
            json.decodeFromString(ListSerializer(BadgeSet.serializer()), text)
        } else {
            println("Api error: $response")
            null
        }
    }

    override suspend fun getCheermotes(broadcasterId: BroadcasterId): List<Cheermote>? {
        val response =
            httpClient.get(urlString = "${apiBaseUrl.substringBeforeLast("/")}/api/twitch/cheermotes?broadcasterId=${broadcasterId.id}")
        return if (response.status.isSuccess()) {
            val text = response.bodyAsText()
            json.decodeFromString(ListSerializer(Cheermote.serializer()), text)
        } else {
            println("Api error: $response")
            null
        }
    }

    override suspend fun getUser(channelName: String): User? {
        val response =
            httpClient.get(urlString = "${apiBaseUrl.substringBeforeLast("/")}/api/twitch/channel/$channelName")
        return if (response.status.isSuccess()) {
            val text = response.bodyAsText()
            json.decodeFromString(User.serializer(), text)
        } else {
            println("Api error: $response")
            null
        }
    }

    override suspend fun getCombinedChannelEmotes(broadcasterId: BroadcasterId): List<ThirdPartyEmote>? {
        val response =
            httpClient.get(urlString = "${apiBaseUrl.substringBeforeLast("/")}/api/combined/broadcasterId/${broadcasterId.id}")
        return if (response.status.isSuccess()) {
            val text = response.bodyAsText()
            json.decodeFromString(ListSerializer(ThirdPartyEmote.serializer()), text)
        } else {
            println("Api error: $response")
            null
        }
    }

}

package dev.moetz.chatoverlay.design

import dev.moetz.materialize.mFooter
import dev.moetz.werbinich.localization.Localization
import react.ChildrenBuilder

fun ChildrenBuilder.mFooter() {
    mFooter(
        socialLink = "https://twitter.com/FlowMo_7",
        pageName = Localization["page_title"],
        gitUrl = "https://gitlab.moetz.dev/twitch/chatoverlay",
        isOpenSource = true,
        buyMeACoffeeName = "flow_mo7",
        linksOnRightSide = listOf(
            "Privacy Policy" to "https://moetz.dev/privacy",
        )
    )
}

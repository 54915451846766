package dev.moetz.chatoverlay.chatclient

import dev.moetz.chatoverlay.model.BroadcasterId
import dev.moetz.chatoverlay.model.IncomingIRC
import dev.moetz.chatoverlay.model.thirdpartyemote.ThirdPartyEmote
import dev.moetz.chatoverlay.model.twitch.BadgeSet
import dev.moetz.chatoverlay.model.twitch.Cheermote
import dev.moetz.chatoverlay.model.twitch.User
import io.ktor.client.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json

abstract class ChatClient(
    protected val apiBaseUrl: String,
    protected val loggingEnabled: Boolean,
    protected val json: Json,
) {

    protected val httpClient: HttpClient by lazy {
        HttpClient() {
            if (loggingEnabled) {
                install(Logging)
            }
        }
    }

    abstract val connectedFlow: StateFlow<Boolean>

    abstract val messagesStateFlow: StateFlow<List<IncomingIRC.Message>>

    abstract val seenRoomIdsFlow: Flow<Set<BroadcasterId>>

    abstract suspend fun connectAndJoinChannels(channels: List<String>, loadRecentMessages: Boolean)

    suspend fun getGlobalBadges(): List<BadgeSet>? {
        val response = httpClient.get(urlString = "${apiBaseUrl.substringBeforeLast("/")}/api/twitch/badges/global")
        return if (response.status.isSuccess()) {
            val text = response.bodyAsText()
            json.decodeFromString(ListSerializer(BadgeSet.serializer()), text)
        } else {
            println("Api error: $response")
            null
        }
    }

    abstract suspend fun getChannelEmotes(channelName: String): BadgeSet?

    abstract suspend fun getChannelEmotesByBroadcasterId(broadcasterId: BroadcasterId): List<BadgeSet>?

    abstract suspend fun getCheermotes(broadcasterId: BroadcasterId): List<Cheermote>?

    abstract suspend fun getUser(channelName: String): User?

    abstract suspend fun getCombinedChannelEmotes(broadcasterId: BroadcasterId): List<ThirdPartyEmote>?

    suspend fun getCombinedGlobalEmotes(): List<ThirdPartyEmote>? {
        val response =
            httpClient.get(urlString = "${apiBaseUrl.substringBeforeLast("/")}/api/combined/global")
        return if (response.status.isSuccess()) {
            val text = response.bodyAsText()
            json.decodeFromString(ListSerializer(ThirdPartyEmote.serializer()), text)
        } else {
            println("Api error: $response")
            null
        }
    }

}

package dev.moetz.chatoverlay.model.thirdpartyemote

import dev.moetz.chatoverlay.model.BroadcasterId
import dev.moetz.chatoverlay.model.EmoteProvider
import dev.moetz.chatoverlay.model.TwitchChannelName
import kotlinx.serialization.Serializable

@Serializable
data class EmoteProviderReloadEvent(
    val channelName: TwitchChannelName,
    val broadcasterId: BroadcasterId,
    val provider: EmoteProvider?
)
